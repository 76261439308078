.coupons__title {
  font-family: "nunito" !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25) !important;
  /* //   background-color: white !important; */
}
.MuiOutlinedInput-inputMarginDense {
  padding-top: 15px !important;
  padding-bottom: 15px !important;

}
.MuiOutlinedInput-adornedStart {
  background-color: white;
}
.date__picker {
  width: 100%;
  outline: none !important;
  border: none !important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 0.7rem;
  font-size: 1rem !important;
}
.coupons__container {
  background: #f9fafe;


  border-radius: 10px;
  border: 1px solid rgba(160, 183, 228, 0.6);
}
.coupon__text {
  font-size: 20px !important;
  line-height: 32.14px !important;
  font-weight: 700 !important;
  font-family: "nunito" !important;
  text-align: center !important;
}
.coupon__small--text {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 27.14px !important;
}
.MuiOutlinedInput-inputAdornedEnd {
  background-color: white !important;
}
.ReactTags__tagInputField {
  width: 100% !important;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
  border: none !important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  font-size: 1rem !important;
  padding-left: 0.7rem;
}
.tag-wrapper {
  border: 2px solid black;
  margin-top: 1rem;
}
.auto .css-qm9mjt-MuiAutocomplete-root {
  padding: 0 !important;
}
.auto .MuiInputBase-input {
  height: 0px !important;
}
.auto .css-qm9mjt-MuiAutocomplete-root {
  padding: 0px !important;
}
.auto
  .css-qm9mjt-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-endAdornment {
  background: white !important;
}
