@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
.discount-icon {
    width: 24px;
    height: 24px;
  }
  
  .discount-icon::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000; /* Replace with your desired color */
    opacity: 0.5; /* Adjust the opacity as needed */
    z-index: 1;
  }
  


.coupons__title {
    font-family: "nunito" !important;
    font-weight: 700 !important;
    font-size: 20px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 10px !important;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25) !important;
    /* //   background-color: white !important; */
  }
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    
  }
  .MuiOutlinedInput-adornedStart {
    background-color: white;
  }
  .date__picker {
    width: 100%;
    outline: none !important;
    border: none !important;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 0.7rem;
    font-size: 1rem !important;
  }
  .coupons__container {
    background: #f9fafe;
    height: 100% !important;
  
 
    border-radius: 10px;
    border: 1px solid rgba(160, 183, 228, 0.6);
  }
  .coupon__text {
    font-size: 20px !important;
    line-height: 32.14px !important;
    font-weight: 700 !important;
    font-family: "nunito" !important;
    text-align: center !important;
  }
  .coupon__small--text {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 27.14px !important;
  }
  .MuiOutlinedInput-inputAdornedEnd {
    background-color: white !important;
  }
  .ReactTags__tagInputField {
    width: 100% !important;
    padding-bottom: 15px !important;
    padding-top: 15px !important;
    border: none !important;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    font-size: 1rem !important;
    padding-left: 0.7rem;
  }
  .tag-wrapper {
    border: 2px solid black;
    margin-top: 1rem;
  }
  .auto .css-qm9mjt-MuiAutocomplete-root {
    padding: 0 !important;
  }
  .auto .MuiInputBase-input {
    height: 0px !important;
  }
  .auto .css-qm9mjt-MuiAutocomplete-root {
    padding: 0px !important;
  }
  .auto
    .css-qm9mjt-MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-endAdornment {
    background: white !important;
  }
  
.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 48px;
    width: 480px;
    padding: 0 8px;
    border: 1px solid rgb(214, 216, 218);
    border-radius: 6px;
    border-radius: 10px !important;
  
    /* // margin-top: 1rem; */
    &:focus-within {
      border: 1px solid #0052cc;
    }
    input {
      flex: 1 1;
      border: none;
      height: 46px;
      font-size: 14px;
      padding: 4px 0 0 0;
      padding-left: 8px;
      padding-right: 8px;
      &:focus {
        outline: transparent;
      }
    }
  }
  
  #tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
  }
  
  .tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: #eee;
    .tag-title {
      margin-top: 3px;
    }
    .tag-close-icon {
      display: block;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      font-size: 14px;
      margin-left: 8px;
      color: #0052cc;
      border-radius: 50%;
      background: #fff;
      cursor: pointer;
    }
  }
  
  @media screen and (max-width: 567px) {
    .tags-input {
      width: calc(100vw - 32px);
    }
  }
  
.coupons__title {
  font-family: "nunito" !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25) !important;
  /* //   background-color: white !important; */
}
.MuiOutlinedInput-inputMarginDense {
  padding-top: 15px !important;
  padding-bottom: 15px !important;

}
.MuiOutlinedInput-adornedStart {
  background-color: white;
}
.date__picker {
  width: 100%;
  outline: none !important;
  border: none !important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 0.7rem;
  font-size: 1rem !important;
}
.coupons__container {
  background: #f9fafe;


  border-radius: 10px;
  border: 1px solid rgba(160, 183, 228, 0.6);
}
.coupon__text {
  font-size: 20px !important;
  line-height: 32.14px !important;
  font-weight: 700 !important;
  font-family: "nunito" !important;
  text-align: center !important;
}
.coupon__small--text {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 27.14px !important;
}
.MuiOutlinedInput-inputAdornedEnd {
  background-color: white !important;
}
.ReactTags__tagInputField {
  width: 100% !important;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
  border: none !important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  font-size: 1rem !important;
  padding-left: 0.7rem;
}
.tag-wrapper {
  border: 2px solid black;
  margin-top: 1rem;
}
.auto .css-qm9mjt-MuiAutocomplete-root {
  padding: 0 !important;
}
.auto .MuiInputBase-input {
  height: 0px !important;
}
.auto .css-qm9mjt-MuiAutocomplete-root {
  padding: 0px !important;
}
.auto
  .css-qm9mjt-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-endAdornment {
  background: white !important;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 480px;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
  border-radius: 10px !important;

  /* // margin-top: 1rem; */
  &:focus-within {
    border: 1px solid #0052cc;
  }
  input {
    flex: 1 1;
    border: none;
    height: 46px;
    font-size: 14px;
    padding: 4px 0 0 0;
    padding-left: 8px;
    padding-right: 8px;
    &:focus {
      outline: transparent;
    }
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #eee;
  .tag-title {
    margin-top: 3px;
  }
  .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #0052cc;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}

body {
  margin: 0px;
}

body .makeStyles-adminRoot-1,
html .makeStyles-adminRoot-1,
.MuiPopover-root,
.MuiDialog-paper,
.Toastify,
.MuiDialog-paperFullScreen {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.medentry {
  border-bottom: 1px solid red !important;
}

.medentry:before {
  border-bottom: 1px solid red !important;
}

.medentry:hover {
  border-bottom: 1px solid red !important;
}

.medentry:after {
  border-bottom: 1px solid red !important;
}

@media only screen and (min-width: 960px) {
  .imageViwer {
    display: flex !important;
    overflow: auto !important;
    height: 80vh !important;
  }

  .imageCard {
    height: 820px;
    width: 100%;
    overflow: auto;
  }
}
@media only screen and (max-width: 960px) {
  .nextIcon {
    position: absolute;
    z-index: 1;
    right: 0%;
    top: 50%;
  }
  .prevIcon {
    position: absolute;
    z-index: 1;
    left: 0%;
    top: 50%;
  }
}
@media only screen and (min-width: 960px) {
  .nextIcon {
    position: absolute;
    z-index: 1;
    right: 52%;
    top: 60%;
  }
  .prevIcon {
    position: absolute;
    z-index: 1;
    top: 60%;
  }
}

@media only screen and (max-width: 960px) {
  .imageViwer {
    display: flex !important;
    overflow: auto !important;
    height: 300px !important;
  }

  .formViwer {
    /* // display: flex !important; */
    overflow: auto !important;
    height: 300px !important;
  }

  .imageCard {
    height: 500px;
    width: 100%;
    overflow: auto;
  }
}

/* // User View Tab fixed for different devices */

@media only screen and (max-width: 960px) {
  .userTableViewTabs {
    margin-top: 80px;
    /* // position: relative;
    // left: 0%; */
  }
}

@media only screen and (min-width: 960px) {
  .userTableViewTabs {
    margin-top: 80px;
    margin-left: 270px;
    /* // position: relative;
    // left: 25%; */
  }
}

.DayPicker-wrapper {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.customLabelWidth .MuiFormLabel-root {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.MuiPickerDTToolbar-toolbar .MuiButton-label {
  padding: 0 !important;
}

.prescriptionImageStyle .MuiTab-wrapper {
  padding: 2px;
  background-image: linear-gradient(
    30deg,
    #fc575e 0%,
    #f7b42c 15%,
    #f7b42c 100%
  );
}

.prescriptionImageStyle .Mui-selected .MuiTab-wrapper {
  background-image: linear-gradient(
    30deg,
    #6da5de 0%,
    #6da5de 15%,
    #7de3dc 100%
  );
}
.dummyPage .MuiListItemIcon-root {
  min-width: 32px !important;
  /* // margin-top: 10px !important; */
}

.processCaseEditTab .MuiTabs-scrollButtons {
  background-color: #fff !important;
  border-radius: 70% !important;
  color: #000 !important;
}

.MuiTab-textColorInherit {
  min-width: auto !important;
}

.MuiTab-textColorInherit .Mui-selected {
  min-width: auto !important;
}

